<template>
    <div>
        <main id="content" role="main">
            <div class="container">
                <div class="my-md-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
                            <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1"><router-link to="/home">Нүүр хуудас</router-link></li>
                            <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 active" aria-current="page">Дэлгүүрийн байршил & Холбоо барих</li>
                        </ol>
                    </nav>
                </div>
                <div class="row mb-8">
                     <div class="col-md-6 col-sm-12">
                        <h5>ИХ НАЯД худалдааны төв</h5>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.5084719553806!2d106.92609177625116!3d47.90719816717179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96932607ea31e9%3A0xbc9260bf99dc7059!2z0JjRhSDQvdCw0Y_QtA!5e0!3m2!1smn!2smn!4v1725965234102!5m2!1smn!2smn" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                     <div class="col-md-6 col-sm-12">
                        <h5>ТЭДИ төв</h5>
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d708.1976548353091!2d106.9052397569845!3d47.92093938259587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693eac900d6fd%3A0x67f3ffd3abe365f7!2zU25vd1BsdXMgTW9uZ29saWEg0KLRjdC00Lgg0YLTqdCy!5e0!3m2!1smn!2smn!4v1725965872073!5m2!1smn!2smn" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                     </div>
                     <div class="col-md-6 col-sm-12">
                        <h5>Баруун 4 зам</h5>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d567.5866729461131!2d106.89404880294619!3d47.91616636156933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693de49abe545%3A0xf140db674022951b!2z0KjQuNC90Y0g0JzQvtC90LPQvtC7INCl0LDQsNC0INCT0YDSr9C_0L8!5e0!3m2!1smn!2smn!4v1725965824531!5m2!1smn!2smn" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                     </div>
                     <div class="col-md-6 col-sm-12">
                        <h5>Ард кино театр</h5>
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d883.74005343636!2d106.90403213988104!3d47.92002288916878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692597c893301%3A0xd9378aebb5085f21!2z0JHQsNGA0YPRg9C9INCh0Y3Qu9Cx0LjQudC9INCz0YPQtNCw0LzQtiAzOS3RgCDQsdCw0LnRgCwgMzkt0YAg0LHQsNC50YAsINC-0YDQvtC9INGB0YPRg9GGLCA5LdC9INC00LDQstGF0LDRgCwg0KfQlCAtIDMg0YXQvtGA0L7Qviwg0KPQu9Cw0LDQvdCx0LDQsNGC0LDRgCAxNTE3MQ!5e0!3m2!1smn!2smn!4v1725966040500!5m2!1smn!2smn" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                     </div>
                    
                </div>
                <div class="row mb-10">
                    <div class="col-md-8 col-xl-9">
                        <div class="mr-xl-6">
                            <div class="border-bottom border-color-1 mb-5">
                                <h3 class="section-title mb-0 pb-2 font-size-25">Санал гомдол</h3>
                            </div>
                            <form class="js-validate" novalidate="novalidate" @submit.prevent="sendMessage">
                               <div class="row">
                                   <div class="col-md-12">
                                        <!-- Input -->
                                        <div class="js-form-message mb-4">
                                            <label class="form-label">
                                                Гарчиг
                                            </label>
                                            <el-input placeholder="..." required maxlength="50" v-model="title"></el-input>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- Input -->
                                        <div class="js-form-message mb-4">
                                            <label class="form-label">
                                                Сэтгэгдэл
                                            </label>
                                            <el-input placeholder="..." maxlength="200" required v-model="message" type="textarea" :rows="3"></el-input>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <el-button type="submit" @click="sendMessage" icon="el-icon-message" plain> Илгээх</el-button>
                                    </div>
                               </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4 col-xl-3">
                        <div class="border-bottom border-color-1 mb-5">
                            <h3 class="section-title mb-0 pb-2 font-size-25">Хаяг</h3>
                        </div>
                        <div class="mr-xl-6">
                            <address class="mb-6">
                                Монгол улс, Улаанбаатар хот, ЧД 1-р хороо, 1-р 40 50 мянгат, Ноён хутагт Данзанравжаа гудамж 17/1 барилга 3-302 тоот
                                <!-- Монгол улс, Улаанбаатар хот, ЧД 4-р хороо,  <br>
                                Жуулчны гудамж 4/4, Макс Цамхаг, <br>
                                3 давхар 306 тоот -->
                            </address>
                            <h5 class="font-size-14 font-weight-bold mb-3">Ажиллах цаг</h5>
                            <ul class="list-unstyled mb-6">
                                <li class="flex-center-between mb-1"><span class="">Даваа</span><span class="">09:00 - 18:00</span></li>
                                <li class="flex-center-between mb-1"><span class="">Мягмар</span><span class="">09:00 - 18:00</span></li>
                                <li class="flex-center-between mb-1"><span class="">Лхагва</span><span class="">09:00 - 18:00</span></li>
                                <li class="flex-center-between mb-1"><span class="">Пүрэв</span><span class="">09:00 - 18:00</span></li>
                                <li class="flex-center-between mb-1"><span class="">Баасан</span><span class="">09:00 - 18:00</span></li>
                                <li class="flex-center-between mb-1"><span class="">Бямба</span><span class="">Амарна</span></li>
                                <li class="flex-center-between"><span class="">Ням</span><span class="">Амарна</span></li>
                            </ul>
                            <h5 class="font-size-14 font-weight-bold mb-3">Ажлын байр</h5>
                            <p class="text-gray-90">Хэрвээ та <strong>ITLAB</strong> дээр ажил эрхлэх сонирхолтой байгаа бол бидэнд имэйл илгээнэ үү: <a class="text-blue text-decoration-on" href="mailto:info@icbc.mn">info@icbc.mn</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '',
            message: ''
        }
    },
    methods: {
        sendMessage() {
            const token = localStorage.getItem('token');
            if(!token) {
                this.$notify({
                    title: 'Амжилтгүй',
                    message: `Нэвтэрсэн байх шаардлагатай`,
                    type: 'error'
                });
                this.$router.push('/login');
            } else {
                if(this.title != '' && this.message != '') {
                    this.$notify({
                        title: 'Амжилттай',
                        message: `Сэтгэгдэл илгээгдлээ`,
                        type: 'success'
                    });
                    this.title = '';
                    this.message = '';
                } else {
                    this.$notify({
                    title: 'Амжилтгүй',
                    message: `Талбараа бүрэн бөглөнө үү`,
                    type: 'error'
                });
                }
            }
        }
    }
}
</script>