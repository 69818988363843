<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  /* height: 120px; */
  border-radius: 10px;
}
.card-read-only {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
}
.card-flex {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
}

.card-active {
    border: 1px solid #fbda0c;
    background:#fbda0c;
    /* background: transparent; */
}

.card-flex:hover {
  box-shadow: 0 8px 16px 0 #fbda0c;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.d-none-width {
    width: 250px; 
    max-width: 250px; 
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.d-none-price {
    width: 100px;
}

@media only screen and (max-width: 600px) {
  .d-none-width {
    width: auto;
    max-width: 100%;
  }
  .d-none-price {
      width: auto;
  }
  .mobile-product {
      padding: 20px;
  }
}
</style>
<template>
    <div>
        <main id="content" role="main" class="cart-page" v-loading.fullscreen.lock="fullscreenLoading">
            <div class="container">
                <div class="row mb-5 mt-5">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <el-steps :active="3">
                            <el-step title="Сагс" align="left" icon="el-icon-sold-out"></el-step>
                            <el-step title="Хаяг" icon="el-icon-truck"></el-step>
                            <el-step align="center" title="Захиалах" icon="el-icon-thumb"></el-step>
                        </el-steps>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>

            <div class="container">
                    <div class="row">
                        <div class="mb-2 cart-table col-md-8">
                            <div class="row">
                                 <div class="col-md-12 p-1"> 
                                    <div class="card-read-only p-4"> 
                                         <img src="@/assets/images/payment/deliver.png" class="mr-3 mt-2" :style="{'width': '120px', 'height': '50px'}">
                                         <span><strong>Санамж</strong><br>
                                            <span class="mt-1" style="line-height: 100%; display: block;">Таны захиалсан бараа захиалга баталгаажсанаас хойш 14-18 хоногт багтан ирэх болно.Та хүргэлтийн төлбөр төлснөөр хүссэн хаягтаа хүргүүлэх эсвэл ойр байрлах ITLAB дэлгүүрийн салбар болон бусад агуулахын хаягаар ирж авах боломжтой.
                                                Таны захиалсан бүх бүтээгдэхүүн даатгалтай тул тээвэрлэлтийн явцад гарсан аливаа гэмтэлийг бид 100% хариуцах болно.</span>
                                        </span>
                                    </div>
                                    <div class="card-read-only p-4 mt-3"> 
                                        <img src="@/assets/images/payment/bonus.png" class="mr-3 mt-2" :style="{'width': '120px', 'height': '50px'}">
                                        <span><strong>Бонус</strong><br>
                                            <span  class="mt-1" style="line-height: 100%; display: block;"> 
                                            Таны худалдан авалт амжилттай болсоны дараа танд худалдан авсан барааны үнийн дүнгийн 1% тай тэнцэх мөнгөн дүнтэй бонусыг буцаан олгох болно. Та энэхүү бонусыг дараагийн худалдан авалтандаа ашиглах боломжтой.
                                         </span>
                                        </span>

                                    </div>
                                    <div class="card-read-only p-4 mt-3" v-if="shipping.amount>0"> 
                                        <img src="@/assets/images/payment/map.png" class="mr-3 mt-2" :style="{'width': '60px', 'height': '50px'}">
                                          <span> 
                                            <strong>Хүргэлтийн хаяг</strong><br>
                                            <span class="mt-1" style="line-height: 100%; display: block;">{{ shipping.district}} дүүрэг {{ shipping.khoroo}}-р хороо {{ shipping.more}}</span>
                                          </span>
                                    </div>
                                    <div class="card-read-only p-4 mt-3" v-else> 
                                        <img src="@/assets/images/payment/map.png" class="mr-3 mt-2" :style="{'width': '60px', 'height': '50px'}">
                                          <span> 
                                            <strong>Хаяг</strong><br>
                                            <span class="mt-1" style="line-height: 100%; display: block;" v-if="shipping.warehouse==1">Баянзүрх дүүрэг, Их наяд худалдааны төв 2 давхарт ITLAB дэлгүүр</span>
                                            <span class="mt-1" style="line-height: 100%; display: block;" v-else-if="shipping.warehouse==2"> Чингэлтэй дүүрэг, Тэди төвийн 3 давхарт ITLAB дэлгүүр. Утас: 70000766</span>
                                            <span class="mt-1" style="line-height: 100%; display: block;" v-else-if="shipping.warehouse==3"> Баянгол дүүрэг, баруун дөрвөн зам Шинэ Монгол Хаад Оффис Магнет карго Утас: 99108235</span>
                                            <span class="mt-1" style="line-height: 100%; display: block;" v-else-if="shipping.warehouse==4"> Чингэлтэй дүүрэг, Ард кино театр, 39-р байр 1-р орц. Утас: 75117733</span>
                                          </span>
                                    </div>
                                 </div>
                                 <div class="mb-3 mt-3" align="left">
                                        <h5 class="ml-1">Төлбөр төлөх боломжууд</h5>
                                 </div>
                                <div class="col-md-12 p-1">
                                    <div class="card-flex p-5" v-bind:class="{'card-active': payment == 'qpay'}" @click="changePayment('qpay')">
                                        <img src="@/assets/images/logo/qpay.png" class="mr-3" :style="{'width': '50px', 'height': '50px'}"/>
                                        <span><strong>QPAY ЭСВЭЛ ДАНСАНД ШИЛЖҮҮЛЭХ</strong> <br> <span class="mt-1" style="line-height: 100%; display: block;">Интернет банкны QR код ашиглан төлбөр төлөх эсвэл төлбөрийг дансанд шилжүүлэх</span></span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 p-1 mt-2">
                                    <div class="card-flex p-5" v-bind:class="{'card-active': payment == 'mongolchat'}" @click="changePayment('mongolchat')">
                                        <img src="@/assets/images/logo/mongolchat.png" class="mr-3" :style="{'width': '50px', 'height': '50px'}"/>
                                        <span><strong>Mongol Chat</strong> <br> <span class="mt-1" style="line-height: 100%; display: block;">Mongol Chat аппликэйшныг ашиглан төлбөр төлөх</span></span>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-12 p-1 mt-2">
                                    <div class="card-flex p-5" v-bind:class="{'card-active': payment == 'storepay'}" @click="changePayment('storepay')">
                                        <img src="@/assets/images/logo/storepay.png" class="mr-3" :style="{'width': '50px', 'height': '50px'}"/>
                                        <span><strong>STOREPAY</strong> <br> <span class="mt-1" style="line-height: 100%; display: block;">Та StorePay үйлчилгээг ашиглан хүүгүй, шимтгэлгүй хуваан төлөх боломжтой</span></span>
                                    </div>
                                </div>
                                <div class="col-md-12 p-1 mt-2">
                                    <div class="card-flex p-5" v-bind:class="{'card-active': payment == 'mcredit'}" @click="changePayment('mcredit')">
                                        <img src="@/assets/images/logo/mcredit.png" class="mr-3" :style="{'width': '50px', 'height': '50px'}"/>
                                        <span><strong>MCREDIT</strong> <br> <span class="mt-1" style="line-height: 100%; display: block;">Хүссэн бараагаа одоо аваад хүү, шимтгэлгүй 4 хуваан төлөх боломжтой</span></span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="mb-8 cart-total col-md-4" align="center">
                            <div class="row card p-5 mb-3">
                                <div class="border-bottom border-color-1 mb-3" align="right">
                                    <h3 class="d-inline-block mb-0 pb-2 font-size-26">Бүтээгдэхүүн</h3>
                                </div>
                                <table class="table mb-3 mb-md-0">
                                    <tbody>
                                        <tr v-for="(item, index) in cart.product" :key="index" class="cart-subtotal">
                                            <th>{{item.name}}</th>
                                            <td data-title="Subtotal"><span class="amount">{{item.quantity}} X ₮{{Number(item.amount*item.quantity).toLocaleString()}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row card p-5">
                                <div class="col-md-14">
                                    <div class="border-bottom border-color-1 mb-3" align="right">
                                        <h3 class="d-inline-block  mb-0 pb-2 font-size-26">Захиалгын мэдээлэл</h3>
                                    </div>
                                    <table class="table mb-3 mb-md-0">
                                        <tbody>
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Үнэ</th>
                                                <td data-title="Үнэ"><span class="amount">₮{{Number(cart.subtotal).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Нэмэгдэх бонус</th>
                                                <td data-title="Нэмэгдэх бонус"><span class="amount">{{Number(parseInt(cart.bonus)).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="cart-subtotal" v-if="cart.useBonus>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Ашигласан бонус</th>
                                                <td data-title="Ашиглах бонус" >{{Number(cart.useBonus)}}</td>
                                            </tr>
                                            <tr class="cart-subtotal" v-if="cart.sale>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Хямдрал</th>
                                                <td data-title="Хямдрал"><span class="amount">₮{{Number(parseInt(cart.sale)).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="cart-subtotal" v-if="coupon.amount>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Хөнгөлөлт</th>
                                                <td data-title="Хөнгөлөлт"><span class="amount">₮{{Number(parseInt(coupon.amount)).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Үйлчилгээний хөлс</th>
                                                <td>₮{{Number(cart.Fee).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total" v-if="shipping.amount>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> Хүргэлт</th>
                                                <td>₮{{Number(shipping.amount).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total" v-if="cart.assemble>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> Угсралт</th>
                                                <td>₮{{Number(cart.assemble).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total" v-if="cart.mow>0">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> Тогтоолт</th>
                                                <td>₮{{Number(cart.mow).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total" v-if="cart.NOAT>0">
                                                 <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> НӨАТ</th> 
                                                 <td>₮{{Number(cart.NOAT).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> Нийт</th>
                                                <td data-title="Нийт"><strong><span class="amount">₮{{Number(cart.sum).toLocaleString()}}</span></strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row pl-1 pr-1">
                                <div class="col-md-12" align="center">
                                    <button type="button" @click="submitForm" class="btn btn-primary-dark-w mt-3" style="width: 100%; border-radius: 12px;">Төлбөр</button>
                                    <button type="button" @click="backRoute" class="btn btn-outline-primary mt-1" style="width: 100%; border-radius: 12px;">Цуцлах</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </main>
        <Brands />
    </div>
</template>
<script>
import Brands from "@/components/brands";
const api = require("@what3words/api");
api.setOptions({ key: "E5XS8ZV3" });
export default {
    components: {
        Brands
    },
    data() {
        return {
            ordernumber:'',
            radio:'1',
            fullscreenLoading: false,
            checked:true,
            deliver:false,
            assemble:false,
            NOAT:false,
            useBonus:false,
            Fee:60000,
            compRegNOAT:'',
            mow:false,
            cart: {
                product: [],
                real: [],
                subtotal: 0,
                sale: 0,
                bonus: 0,
                useBonus: 0,
                bonusLimit: 0,
                assemble:0,
                Fee:0,
                mow:0,
                NOAT:0,
                sum:0


            },
            shipping: {
                amount: 0,
                warehouse:0,
                district:'',
                khoroo:0,
                more:''
            },
            range: {
                district: [],
            },
            coupon: {
                code: null,
                active: false,
                amount: 0
            },
            payment: 'qpay'
        }
    },
    mounted() {
        this.$emit('getJquery');
        this.ordernumber=this.$route.params.id
        this.getOrder();
    },
    computed:{
        totalPrice(){
            var total=Number(parseInt(this.cart.subtotal))
            if(this.deliver==true){
                // Number(parseInt(cart.subtotal - cart.sale) + shipping.amount - coupon.amount - parseInt(cart.useBonus)).toLocaleString()
                total=Number(parseInt(total*0.02+total));
            }
            if(this.assemble==true){
                total=Number(total+50000);
            }
            if(this.NOAT==true){
               total=Number(parseInt(total+this.cart.subtotal*0.1));
            }
            if(this.mow==true){
                total=Number(parseInt(total+25000));
            }
            total=Number(parseInt(total- this.coupon.amount -this.cart.useBonus- this.cart.sale)).toLocaleString();
            return total;
        },

    },
    watch:{
      'useBonus':function(val){
         if(val==true){
             document.getElementById('bonus').classList.remove('addBonus');
            }else{
            document.getElementById('bonus').classList.add('addBonus');
         }
      }
    },
    methods: {
        compRegForNOAT(){
            var noat=this.NOAT
            if(noat==true){
                window.document.getElementById('noatDet').classList.remove("notNone");
                window.document.getElementById('noatDet').classList.add("showNOAT");
            }else{
                window.document.getElementById('noatDet').classList.remove("showNOAT");
                window.document.getElementById('noatDet').classList.add("notNone");
            }
        },
        backRoute() {
            this.$router.push('/shipping');
        },
        changeBonus() {
            localStorage.bonus = this.cart.useBonus;
        },
        calculateBonusLimit() {
            var l = localStorage.getItem('bonusLimit');
            if(l != null) {
                this.cart.bonusLimit = parseInt(l);
            }
            var b = localStorage.getItem('bonus');
            if(b != null) {
                this.cart.useBonus = parseInt(b);
            }
        },
        changePayment(pay) {
            this.payment = pay;
        },
        RemoveCoupon() {
            localStorage.removeItem('coupon');
            this.coupon.code = null;
            this.coupon.amount = 0;
            this.coupon.active = false;
        },
        useCoupon() {
            var rts = this;
            const token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: rts.$appUrl + '/api/product/use-coupon',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    coupon: this.coupon.code
                }
            }).then(data => {
                if (data.data.result == 'failed') {
                    rts.$notify({
                        title: 'Амжилтгүй',
                        message: `Купон код буруу эсвэл ашиглагдсан байна`,
                        type: 'error'
                    });
                } else {
                    var am = this.cart.subtotal - this.cart.sale;
                    if(parseInt(am/2)<data.data.promo[0].amount){
                        rts.coupon.amount=parseInt(am/2);
                        localStorage.coupon = JSON.stringify({code: rts.coupon.code, amount:parseInt(am/2)});
                    }else{
                        rts.coupon.amount = data.data.promo[0].amount;
                        localStorage.coupon = JSON.stringify({code: rts.coupon.code, amount: data.data.promo[0].amount});
                    }
                    rts.coupon.active = true;
                    rts.$notify({
                        title: 'Амжилттай',
                        dangerouslyUseHTMLString: true,
                        message: `Үнийн дүнгээс <strong>${rts.coupon.amount}₮</strong> хасагдлаа`,
                        type: 'success'
                    });
                }
            });
        },
        calculateShipping() {
           if(this.cart.subtotal - this.cart.sale - this.coupon.amount > 200000) {
                this.shipping.amount = 5000;
            } else {
                this.shipping.amount = parseInt(localStorage.getItem('shipping'));
                this.range.district.forEach(element => {
                    if(element.district == this.shipping.user.district) {
                        var cheap = []; var exp = [];
                        if (element.cheap_khoroo != null) {
                            cheap = element.cheap_khoroo.split(',');
                        }
                        if (element.middle_khoroo != null) {
                            exp = element.middle_khoroo.split(',');
                        }
                        if(cheap.includes(String(this.shipping.user.khoroo))) {
                            this.shipping.amount = 3000;
                        } else if(exp.includes(String(this.shipping.user.khoroo))) {
                            this.shipping.amount = 8000;
                        } else {
                            this.shipping.amount = 5000;
                        }
                    }
                });
            }
            localStorage.shipping = this.shipping.amount;
        },
        calculate() {
            var subtotal = 0; var sale = 0; var bonus = 0;
            this.cart.product.forEach(element => {
                subtotal = subtotal + (element.amount * element.quantity);
            });
            this.cart.subtotal = subtotal;
        },
        submitForm() {
            if(this.payment == '') {
                this.$notify({
                    title: 'Амжилтгүй',
                    message: `Төлбөрийн нөхцөлөө сонгоно уу`,
                    type: 'error'
                });
            } else {
                this.fullscreenLoading = true;
                const token = localStorage.getItem('token');
                var rts = this;
                rts.$axios({
                    method: 'POST',
                    url: rts.$appUrl + '/api/purchase/submit-order',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                         ordernumber:this.ordernumber,
                         payment:this.payment
                    }
                }).then(data => {
                    rts.fullscreenLoading = false;
                    if(data.data.result == 'failed') {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: data.data.data,
                            type: 'error'
                        });
                    } else {
                        rts.$emit('calcCart');
                        rts.$router.push({name: 'show-order', params: {invoiceId: data.data.invoiceId}});
                    }
                }).catch(err => {
                    rts.fullscreenLoading = false;
                    console.log(err);
                });
            }
        },
        getOrder() {
            this.fullscreenLoading = true;
            var rts = this;
            rts.$axios({
                method: 'POST',
                url: rts.$appUrl + '/api/product/get-incomplete-order',
                headers: {},
                data: {
                    ordernumber:this.ordernumber
                }
            }).then(function(data) {
                rts.fullscreenLoading = false;
                rts.cart.real = data.data.products;
                rts.cart.product = data.data.products;
                rts.cart.bonus=data.data.order.add_bonus;
                rts.cart.useBonus=data.data.order.used_bonus;
                rts.cart.sale=data.data.order.discount;
                rts.coupon.amount=data.data.order.coupon;
                rts.cart.Fee=data.data.order.fee;
                rts.shipping.amount=data.data.order.tax;
                rts.cart.assemble=data.data.order.assemble;
                rts.cart.mow=data.data.order.mow;
                rts.cart.NOAT=data.data.order.NOAT;
                rts.cart.sum=data.data.order.total_amount;
                rts.shipping.district=data.data.order.district;
                rts.shipping.khoroo=data.data.order.khoroo;
                rts.shipping.more=data.data.order.more_address;
                rts.shipping.warehouse=data.data.order.warehouse;
                rts.calculate();
            }).catch(error => {
                rts.fullscreenLoading = false;
                console.log(error);
            })
            this.calculateShipping();
            this.calculateBonusLimit();
        }
    }
}
</script>