<template>
    <div>
        <main id="content" role="main" v-loading.fullscreen.lock="fullscreenLoading">
            <div class="container">
                <div class="my-md-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
                            <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1"><router-link to="/home">Нүүр хуудас</router-link></li>
                            <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 active" aria-current="page">Ангилал</li>
                        </ol>
                    </nav>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-wd-12gdot5">
                                <!-- <div class="bg-gray-1 flex-center-between borders-radius-9 py-1">
                                    <div class="px-3 d-none d-xl-block">
                                        <ul class="nav nav-tab-shop" id="pills-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="false">
                                                    <div class="d-md-flex justify-content-md-center align-items-md-center">
                                                        <i class="fa fa-th"></i>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="true">
                                                    <div class="d-md-flex justify-content-md-center align-items-md-center">
                                                        <i class="fa fa-list"></i>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                                <div>
                                    <div>
                                        <ul class="row list-unstyled products-group no-gutters">
                                            <li v-for="(sub,index) in subCategories" :key="index" class="col-6 col-md-3 col-wd-2gdot4 product-item" >
                                                <div class="product-item__outer h-100">
                                                    <div class="product-item__inner px-xl-4 p-3">
                                                        <div class="product-item__body pb-xl-2">
                                                        
                                                            <h5 class="mb-1 product-item__title"><router-link :to="'/sub-category/'+sub.id" class="text-blue font-weight-bold">{{sub.sub_category_name}}</router-link></h5>
                                                            <div class="mb-2">
                                                                <router-link :to="'/sub-category/'+sub.id" class="d-block text-center"><img class="img-fluid" :src="sub.image" alt="Image Description"></router-link>
                                                            </div>
                                                            <div class="flex-center-between mb-1">
                                                                <!-- <div v-if="item.discount > 0" class="prodcut-price d-flex align-items-center flex-wrap position-relative">
                                                                    <ins class="font-size-20 text-red text-decoration-none mr-2">₮{{Number(item.sale_price * ((100 - item.discount) / 100)).toLocaleString()}}</ins>
                                                                    <del class="font-size-12 tex-gray-6 position-absolute bottom-100" style="top: -9px;">₮{{Number(item.sale_price).toLocaleString()}}</del>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                </div>

            </div>
        </main>
        <Brands />
    </div>
</template>
<script>
import Brands from "@/components/brands";
export default {
    components: {
        Brands
    },
    data() {
        return {
            subCategories:"",
            fullscreenLoading: false,
            products: [],
            cat:10
        }
    },
    mounted() {
        this.cat=this.$route.params.category; 
        this.getSubcategories();
        this.$emit('getJquery');
    },
    beforeRouteUpdate (to, from, next) {
        this.cat = to.params.category;
        this.getSubcategories();
        this.$emit('getJquery');
        next();
    },
    methods: {
        addCompare(item) {
            this.$emit('addCompare', item);
        },
        addFav(item) {
            this.$emit('addFavourite', item);
        },
        cart(item) {
            this.$emit('addCart', [1, item]);
        },
        getProduct() {
            var rts = this;
            this.$axios({
                method: 'post',
                url: rts.$appUrl +'/api/product/top20',
                headers: {}
            }).then(data => {
                rts.products = data.data.top;
            });
        },
        getSubcategories(){
            var rts=this;
            this.$axios({
                method:'post',
                url:rts.$appUrl+'/api/home/getsub',
                data:{
                    cat:this.cat
                }
            }).then(data=>{
                if(data.data.result=="success"){
                    rts.subCategories=data.data.subcat;
                }
            })
        }
    }
}
</script>