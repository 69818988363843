<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  /* height: 120px; */
  border-radius: 10px;
}
.disable-address,.disable-address input[type='radio']{
    opacity: 0.5;
    pointer-events: none;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.d-none-width {
    width: 250px; 
    max-width: 250px; 
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.d-none-price {
    width: 100px;
}

@media only screen and (max-width: 600px) {
  .d-none-width {
    width: auto;
    max-width: 100%;
  }
  .d-none-price {
      width: auto;
  }
  .mobile-product {
      padding: 20px;
  }
}
</style>
<template>
    <div>
        <main id="content" role="main" class="cart-page" v-loading.fullscreen.lock="fullscreenLoading">
            <div class="container">
                <div class="row mb-5 mt-5">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <el-steps :active="2">
                            <el-step title="Сагс" align="left" icon="el-icon-sold-out"></el-step>
                            <el-step title="Хаяг" icon="el-icon-truck"></el-step>
                            <el-step align="center" title="Захиалах" icon="el-icon-thumb"></el-step>
                        </el-steps>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>

            <div class="container">
                <form @submit.prevent="submitForm" ref="form" id="form">
                    <div class="row">
                        <div class="mb-2 cart-table col-md-8 card p-5" >
                            <div class="row">
                                <div class="col-md-6 mt-2">
                                    <button type="button" class="btn btn-outline-primary" @click="changePersonal(true)" v-bind:class="{'btn-primary-dark-w': shipping.user.personal == true}" style="width: 100%; border-radius: 10px; height: 70px;"><i class="fas fa-user-tie lg pr-1"></i> Хувь хүн</button>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <button type="button" class="btn btn-outline-primary" @click="changePersonal(false)" v-bind:class="{'btn-primary-dark-w': shipping.user.personal == false}" style="width: 100%; border-radius: 10px; height: 70px;"><i class="fas fa-store-alt lg pr-1"></i> Албан байгууллага</button>
                                </div>
                            </div>
                            <div class="mt-2" align="left">
                                     <h5 class="ml-1">Холбоо барих</h5>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Нэр <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder=""
                                        required
                                        v-model="shipping.user.name"
                                        clearable>
                                    </el-input>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Утасны дугаар <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder="STOREPAY ашиглах бол энд дугаараа оруулна уу"
                                        required
                                        pattern="\d{8}"
                                        v-model="shipping.user.phone"
                                        clearable>
                                    </el-input>
                                </div>
                                <!-- <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Имэйл хаяг <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder=""
                                        required
                                        pattern="[a-zA-Z0-9._\-]{3,}@[a-zA-Z0-9_-]{2,}\.[a-zA-Z]{2,6}"
                                        v-model="shipping.user.email"
                                        clearable>
                                    </el-input>
                                </div> -->
                                <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Имэйл хаяг <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder=""
                                        required
                                        type="email"
                                        v-model="shipping.user.email"
                                        clearable>
                                    </el-input>
                                </div>
                                <div v-if="shipping.user.personal == true" class="col-md-6 mt-2">
                                    <label class=""> <strong>Утасны дугаар 2</strong></label>
                                    <el-input
                                        placeholder=""
                                        pattern="\d{8}"
                                        v-model="shipping.user.phone2"
                                        clearable>
                                    </el-input>
                                </div>
                                <div v-else class="col-md-6 mt-2">
                                    <label class=""> <strong>Байгууллагын регистр <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder=""
                                        required
                                        pattern="\d{7}"
                                        v-model="shipping.user.register"
                                        clearable>
                                    </el-input>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="mt-1" align="left">
                                     <h5 class="ml-3">Хүргэлтийн хаяг</h5>
                                </div>
                                    <div class="col-md-12" style="margin-top: 15px;">
                                    <el-input placeholder="гурван.үгт.хаяг (заавал биш)" v-model="shipping.w3w" class="input-with-select">
                                        <el-button slot="prepend" class="p-1" :style="{'background': '#fff', 'disabled': 'true', 'cursor': 'default'}"><img src="@/assets/images/logo/w3w.png" width="30px" height= "29px"></el-button>  
                                        <el-button slot="append" @click="getWhat3Words" class="p-2" :style="{'background': '#fff'}"><img src="@/assets/images/logo/location.png" width="22px" height= "21px"></el-button>
                                    </el-input>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Дүүрэг <span class="text-danger">*</span></strong></label>
                                    <el-select @change="calculateShipping" v-model="shipping.user.district" filterable placeholder="Дүүрэг" :style="{'width': '100%'}">
                                        <el-option
                                            v-for="(item, index) in range.district"
                                            :key="index"
                                            :label="item.district"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class=""> <strong>Хороо <span class="text-danger">*</span></strong></label>
                                    <el-select @change="calculateShipping" v-model="shipping.user.khoroo" filterable placeholder="Хороо" :style="{'width': '100%'}">
                                        <el-option
                                            v-for="(item, index) in selectKhoroo(shipping.user.khoroo)"
                                            :key="index"
                                            :label="item + '-р хороо'"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label class=""> <strong>Дэлгэрэнгүй хаяг <span class="text-danger">*</span></strong></label>
                                    <el-input
                                        placeholder=""
                                        required
                                        maxlength="100"
                                        type="textarea"
                                        :autosize="{ minRows: 3, maxRows: 5}"
                                        v-model="shipping.user.more"
                                        clearable>
                                    </el-input>
                                </div>
                            </div>
                            <div class="row" id="disable-address">
                                <div class="mt-1" align="left">
                                     <h5 class="ml-3">Очиж авах хаяг</h5>
                                </div>
                                <div class="px-3 py-2"  style="border:solid 1px #d0d2d6;border-radius: 5px;margin:0 15px;width: 100%;">
                                    <div >
                                        <input type="radio" v-model="shipping.warehouse" value="1" > <span>Баянзүрх дүүрэг, Их наяд худалдааны төв 2 давхарт ITLAB дэлгүүр</span>
                                    </div>
                                    <div>
                                        <input type="radio" v-model="shipping.warehouse" value="2">  Чингэлтэй дүүрэг, Тэди төвийн 3 давхарт ITLAB дэлгүүр. Утас: 70000766
                                    </div>
                                    <div>
                                        <input type="radio" v-model="shipping.warehouse" value="3">  Баянгол дүүрэг, баруун дөрвөн зам Шинэ Монгол Хаад Оффис Магнет карго Утас: 99108235
                                    </div>
                                    <div>
                                        <input type="radio" v-model="shipping.warehouse" value="4">  Чингэлтэй дүүрэг, Ард кино театр, 39-р байр 1-р орц. Утас: 75117733
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-8 cart-total col-md-4" align="center">
                            <div class="row card p-5 mb-3">
                                <div class="border-bottom border-color-1 mb-3" align="right">
                                    <h3 class="d-inline-block mb-0 pb-2 font-size-26">Бүтээгдэхүүн</h3>
                                </div>
                                <table class="table mb-3 mb-md-0">
                                    <tbody>
                                        <tr v-for="(item, index) in cart.product" :key="index" class="cart-subtotal">
                                            <th>{{item.name}}</th>
                                            <td data-title="Subtotal"><span class="amount">{{item.qty}} X ₮{{Number(item.price).toLocaleString()}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row card p-5">
                                <div class="col-md-14">
                                    <div class="border-bottom border-color-1 mb-3" align="right">
                                        <h3 class="d-inline-block  mb-0 pb-2 font-size-26">Захиалгын мэдээлэл</h3>
                                    </div>
                                    <table class="table mb-3 mb-md-0">
                                        <tbody>
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Үнэ</th>
                                                <td data-title="Үнэ"><span class="amount">₮{{Number(cart.subtotal).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Нэмэгдэх бонус</th>
                                                <td data-title="Нэмэгдэх бонус"><span class="amount">₮{{Number(parseInt(cart.bonus)).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="useBonus" style="margin-right: 10px;"> </el-checkbox> Ашиглах бонус</th>
                                                <td data-title="Ашиглах бонус" ><el-input-number style="width: 120px" class="amount" @change="changeBonus" :min="0" size="mini" :max="this.cart.bonusLimit" v-model="cart.useBonus" id="bonus" :class="'addBonus'"></el-input-number></td>
                                            </tr>
                                            <!-- <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Хямдрал</th>
                                                <td data-title="Хямдрал"><span class="amount">₮{{Number(parseInt(cart.sale)).toLocaleString()}}</span></td>
                                            </tr> -->
                                            <tr class="cart-subtotal">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Хөнгөлөлт</th>
                                                <td data-title="Хөнгөлөлт"><span class="amount">₮{{Number(parseInt(coupon.amount)).toLocaleString()}}</span></td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"> </el-checkbox> Үйлчилгээний хөлс</th>
                                                <td>₮{{(cart.Fee).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="deliver" style="margin-right: 10px;" id="deliver"></el-checkbox> Хүргэлт</th>
                                                <td>₮{{(shipping.amount).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="assemble" style="margin-right: 10px;"></el-checkbox> Угсралт</th>
                                                <td>₮{{(cart.assemble).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="mow" style="margin-right: 10px;"></el-checkbox> Тогтоолт</th>
                                                <td>₮{{(cart.mow).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                        <th ><el-checkbox v-model="NOAT" style="margin-right: 10px;"></el-checkbox> НӨАТ </th> 
                                                        <td>₮{{(cart.NOAT).toLocaleString()}}</td>
                                            </tr>
                                            <tr class="order-total">
                                                <th><el-checkbox v-model="checked" disabled style="margin-right: 10px;"></el-checkbox> Нийт</th>
                                                <td data-title="Нийт"><strong><span class="amount">₮{{(cart.sum-parseInt(cart.useBonus)).toLocaleString()}}</span></strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row p-2 card mt-3">
                                <div v-if="coupon.active == false" class="col-md-12" align="center">
                                    <el-collapse :style="{'border': 'none'}">
                                        <el-collapse-item name="1">
                                            <template slot="title" :style="{'vertical-align': 'middle'}">
                                                <!-- <TagIcon :style="{'width': '20px', 'height': '20px', 'margin-top': '-3px', 'margin-right': '5px'}" /> Купон код ашиглах -->
                                                <i class="fas fa-wallet fa-lg mr-3"></i> Купон код ашиглах
                                            </template>
                                            <div class="flex">
                                                <el-input placeholder="Купон кодоо оруулна уу" class="input-with-select" v-model="coupon.code" clearable>
                                                    <el-button @click="useCoupon" slot="append" :style="{'background': '#393d3e', 'color': '#fff'}">Ашиглах</el-button>
                                                </el-input>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </div>
                                <div v-else>
                                    <el-alert type="warning" @close="RemoveCoupon">
                                        <template slot="title">
                                            <TagIcon :style="{'width': '20px', 'height': '20px', 'margin-right': '5px'}"/> {{coupon.code}}
                                        </template>
                                    </el-alert>
                                </div>
                            </div>
                            <div class="row pl-1 pr-1">
                                <div class="col-md-12" align="center">
                                    <button type="button" @click="submitForm" class="btn btn-primary-dark-w mt-3" style="width: 100%; border-radius: 12px;">Баталгаажуулах</button>
                                    <button type="button" @click="backRoute" class="btn btn-outline-primary mt-1" style="width: 100%; border-radius: 12px;">Сагс руу буцах</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </main>
        <Brands />
        <el-dialog title="Газрын зураг" :visible.sync="dialogMap" :modalAppendToBody="false">
            <Map />
        </el-dialog>
    </div>
</template>
<script>
import Brands from "@/components/brands";
import Map from "@/components/map";
const api = require("@what3words/api");
api.setOptions({ key: "E5XS8ZV3" });

export default {
    components: {
        Brands,
        Map
    },
    data() {
        return {
            checked:true,
            deliver:false,
            assemble:false,
            NOAT:false,
            useBonus:false,
            compRegNOAT:'',
            mow:false,
            map: null,
            dialogMap: false,
            fullscreenLoading: false,
            cart: {
                product: [],
                real: [],
                subtotal: 0,
                sale: 0,
                bonus: 0,
                useBonus: 0,
                bonusLimit: 0,
                assemble:30000,
                Fee:0,
                mow:20000,
                NOAT:0,
                sum:0
            },
            shipping: {
                warehouse:1,
                amount: 0,
                user: {
                    personal: true,
                    district: '',
                    khoroo: 1,
                    more: '',
                    name: '',
                    phone: '',
                    phone2: '',
                    email: '',
                    register: '',
                    bonus: 0
                },
                w3w: '',
                lat: 47.919143,
                long: 106.917597
            },
            range: {
                district: [],
            },
            coupon: {
                code: null,
                active: false,
                amount: 0
            }
        }
    },
    mounted() {
        this.$emit('getJquery');
        this.getProduct();
    },
    watch:{
        'NOAT':function(val){
            if(val){
              this.cart.sum=this.cart.sum+this.cart.NOAT
            }else{
                this.cart.sum=this.cart.sum-this.cart.NOAT
            }
         },
        'assemble':function(val){
            if(val){
                this.cart.sum=this.cart.sum+this.cart.assemble;
            }else{
                this.cart.sum=this.cart.sum-this.cart.assemble;
            }
         },
        'deliver':function(val){
            if(val){
             this.cart.sum=this.cart.sum+this.shipping.amount;
             this.shipping.warehouse=0;
             document.getElementById('disable-address').classList.add('disable-address');
            }else{
                this.shipping.warehouse=1
                this.assemble=false;
                this.mow=false;
                this.cart.sum=this.cart.sum-this.shipping.amount
                document.getElementById('disable-address').classList.remove('disable-address');
            }
         },
         'mow':function(val){
            if(val){
              this.cart.sum=this.cart.sum+this.cart.mow;
            }else{
                this.cart.sum=this.cart.sum-this.cart.mow;
            }
         },
        'useBonus':function(val){
         if(val==true){
             document.getElementById('bonus').classList.remove('addBonus');
            }else{
            document.getElementById('bonus').classList.add('addBonus');
            this.cart.useBonus=0;
         }
         }
    },
    methods: {
        backRoute() {
            this.$router.push('/cart');
        },
        getCenter() {
            console.log(map.getCenter().toJSON());
        },
        changeBonus() {
            localStorage.bonus = this.cart.useBonus;
        },
        calculateBonusLimit() {
            var am = this.cart.subtotal - this.cart.sale  - this.coupon.amount;
            var limit = parseInt(am / 2);
            
            if(limit > this.shipping.user.bonus) {
                this.cart.bonusLimit = this.shipping.user.bonus;
            } else {
                this.cart.bonusLimit = limit;
            }
            localStorage.bonusLimit = this.cart.bonusLimit;
            var b = localStorage.getItem('bonus');
            if(b != null) {
                this.cart.useBonus = b;
            } else {
                this.cart.useBonus = this.cart.bonusLimit;
                localStorage.bonus = this.cart.useBonus;
            }
        },
        getWhat3Words() {
            var rts = this;
            navigator.geolocation.getCurrentPosition(function (params) {
                rts.shipping.lat = params.coords.latitude;
                rts.shipping.long = params.coords.longitude;
                api.convertTo3wa({lat:params.coords.latitude, lng:params.coords.longitude, language: "mn"})
                .then(data => {
                    rts.shipping.w3w = data.words;
                    localStorage.w3w = data.words;
                });
            });

        },
        RemoveCoupon() {
            this.cart.sum=this.cart.sum+this.coupon.amount;
            localStorage.removeItem('coupon');
            this.coupon.code = null;
            this.coupon.amount = 0;
            this.coupon.active = false;
        },
        useCoupon() {
            var rts = this;
            const token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: rts.$appUrl + '/api/product/use-coupon',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    coupon: this.coupon.code
                }
            }).then(data => {
                if (data.data.result == 'failed') {
                    rts.$notify({
                        title: 'Амжилтгүй',
                        message: `Купон код буруу эсвэл ашиглагдсан байна`,
                        type: 'error'
                    });
                } else {
                    var am = this.cart.subtotal - this.cart.sale;
                    if(parseInt(am/2)<data.data.promo[0].amount){
                        rts.coupon.amount=parseInt(am/2);
                        localStorage.coupon = JSON.stringify({code: rts.coupon.code, amount:parseInt(am/2)});
                    }else{
                        rts.coupon.amount = data.data.promo[0].amount;
                        localStorage.coupon = JSON.stringify({code: rts.coupon.code, amount: data.data.promo[0].amount});
                    }
                    rts.coupon.active = true;
                    rts.cart.sum=rts.cart.sum-rts.coupon.amount;
                    rts.$notify({
                        title: 'Амжилттай',
                        dangerouslyUseHTMLString: true,
                        message: `Үнийн дүнгээс <strong>${rts.coupon.amount}₮</strong> хасагдлаа`,
                        type: 'success'
                    });
                }
            });
        },
        calculateShipping() {
                this.shipping.amount = parseInt(localStorage.getItem('shipping'));
                if(this.deliver){
                    this.cart.sum=this.cart.sum-this.shipping.amount;
                }
                this.range.district.forEach(element => {
                    if(element.id == this.shipping.user.district) {
                        var cheap = []; var exp = [];
                        if (element.cheap_khoroo != null) {
                            cheap = element.cheap_khoroo.split(',');
                        }
                        if (element.middle_khoroo != null) {
                            exp =element.middle_khoroo.split(',');
                        }
                        if(cheap.includes(String(this.shipping.user.khoroo))) {
                            this.shipping.amount = 6000;
                        } else if(exp.includes(String(this.shipping.user.khoroo))) {
                            this.shipping.amount = 15000;
                        } else {
                            this.shipping.amount = 8000;
                        }
                    }
                });
            localStorage.shipping = this.shipping.amount;
            if(this.deliver){
               this.cart.sum=this.cart.sum+this.shipping.amount;
            }
        },
        selectKhoroo() {
            // console.log(this.range.district);
            var khoroo = 0;
            this.range.district.forEach(element => {
                if(element.id == this.shipping.user.district) {
                    khoroo = element.number_of_khoroo;
                }
            });
            return khoroo;
        },
        changePersonal(value) {
            this.shipping.user.personal = value;
        },
        calculate() {
            var subtotal = 0; var sale = 0; var bonus = 0;var fee=0;var NOAT=0;var sum=0;
            this.cart.product.forEach(element => {
                subtotal = subtotal + (element.price * element.qty);
                if(element.discount > 0) {
                    sale = sale + ((element.price - (element.price * ((100 - element.discount) / 100 ))) * element.qty);
                    bonus = bonus + (((element.price * ((100 - element.discount) / 100 )) * (element.bonus / 100)) * element.qty);
                } else {
                    bonus = bonus + ((element.price * (element.bonus / 100)) * element.qty);
                }
            });
                if(subtotal*0.02>50000){
                    fee=50000;
                }else if(subtotal*0.02<5000){
                    fee=5000;
                }else{
                   fee=Math.ceil((subtotal*0.02) / 100) * 100;
                }
                NOAT=subtotal*0.1;
                sum=subtotal+fee-sale;
            this.cart.NOAT=NOAT
            this.cart.Fee=fee;
            this.cart.subtotal = subtotal;
            this.cart.bonus = bonus;
            this.cart.sale = sale;
            this.cart.sum=sum
        },
        changeQty(id) {
            localStorage.cart = JSON.stringify(this.cart.product);
            this.$emit('calcCart');
            this.calculate();
        },
        calcMaxQty(id) {
            var remain = 0;
            this.cart.real.forEach(element => {
                if(element.id == id) {
                    remain = element.remain;
                }
            });
            return remain;
        },
        submitForm() {
            const token = localStorage.getItem('token');
            var rts = this;
            if(!rts.assemble){
                rts.cart.assemble=0;
            }
            if(!rts.NOAT){
                rts.cart.NOAT=0;
            }
            if(!rts.useBonus || rts.cart.useBonus==0){
                rts.cart.useBonus=0
            }
            if(!rts.mow){
                rts.cart.mow=0;
            }
            if(!rts.deliver){
                rts.shipping.amount=0;
            }
            if(!rts.deliver && rts.shipping.warehouse==0){
                rts.$notify({
                        title: 'Амжилтгүй',
                        message: `Хүргэлт сонгох эсвэл очиж авах хаяг сонгоно уу`,
                        type: 'error'
                    });
            }else{
                rts.$axios({
                method: 'POST',
                url: rts.$appUrl + '/api/product/create-order',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    shipping: this.shipping,
                    cart:this.cart,
                    deliver:this.deliver,
                    coupon:this.coupon
                }
            }).then(data => {
                if(data.data.result == 'success') {
                    localStorage.removeItem('bonusLimit');
                    localStorage.removeItem('shipping');
                    localStorage.removeItem('coupon');
                    localStorage.removeItem('cart');
                    localStorage.removeItem('bonus');
                    rts.$router.push('/confirm-order/'+data.data.ordernumber);
                } else {
                    rts.$notify({
                        title: 'Амжилтгүй',
                        message: `Уучлаарай. Өөрийн имэйл хаягыг оруулна уу.`,
                        type: 'error'
                    });
                }
            })
            }
        },
        getProduct() {
            this.shipping.w3w = localStorage.getItem('w3w');
            var coupon = JSON.parse(localStorage.getItem('coupon'));
            if(coupon != null) {
                this.coupon.code = coupon.code;
                this.coupon.amount = parseInt(coupon.amount);
            }
            var reg = localStorage.getItem('reg');
            if(reg != null) {
                shipping.user.register = reg;
            }
            var ids = [];
            let cart = JSON.parse(localStorage.getItem('cart'));
            if(cart == null || cart.length == 0) {
                this.$router.push('/error-page');
            }else{
                cart.forEach(el=>{
                    ids.push(el.id);
                })
            }
            console.log(ids);
            const token = localStorage.getItem('token');
            var rts = this;
            rts.$axios({
                method: 'POST',
                url: rts.$appUrl + '/api/product/cart-shipping',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    coupon: this.coupon.code,
                    id:ids
                }
            }).then(function(data) {
                rts.shipping.user.name = data.data.address[0].name;
                rts.shipping.user.phone = data.data.address[0].phone;
                rts.shipping.user.phone2 = data.data.address[0].phone2;
                rts.shipping.user.email = data.data.address[0].email;
                rts.shipping.user.bonus = data.data.address[0].bonus;
                if(data.data.address[0].district != 0 &&  data.data.address[0].district != null) {
                    rts.shipping.user.district = data.data.address[0].district;
                    
                    if(data.data.address[0].khoroo == null || data.data.address[0].khoroo == 0) {
                        rts.shipping.user.khoroo = 1;
                    } else {
                        rts.shipping.user.khoroo = data.data.address[0].khoroo;
                    }
                    rts.shipping.user.more = data.data.address[0].more_address;
                } else {
                    rts.shipping.user.district = data.data.districts[0].id;
                }
                rts.range.district = data.data.districts;
                if(data.data.coupon == null) {
                    rts.coupon.code = null;
                    rts.coupon.amount = 0;
                } else {
                    rts.coupon.active = true;
                    rts.coupon.amount = data.data.coupon[0].amount;
                    localStorage.coupon = JSON.stringify({code: rts.coupon.code, amount: data.data.coupon[0].amount});
                }
                cart.forEach(el=>{
                    data.data.price.forEach(p=>{
                        if(el.id==p.id){
                            el.price=p.price_tug;
                        }
                    })
                })
                localStorage.cart=JSON.stringify(cart);
                rts.cart.product = cart;
                rts.calculate();
                rts.calculateShipping();
                rts.calculateBonusLimit();
            }).catch(error => {
                console.log(error);
            })
        }
    }
}
</script>